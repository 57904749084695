.report-display-container {
  .report-display-content {
    position: relative;
    min-height: 500px;
    &>div {
      position: absolute;
      height: 100%;
      width: 100%;
    }
  }
}
