.App {
  box-sizing: border-box;
  background-color: #eaeaea;
  min-height: 500px;
  .app-header {
    background-color: #3B3A39;
    color: #F3F2F1;
    font-size: 16px;
    font-weight: 600;
    line-height: 28px;
  }
}
